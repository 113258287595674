/// <reference path="jquery.d.ts" />

class StyleSwitcher
{
    private static readCookie(name: string): string
    {
        const nameEq = name + "=";
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++)
        {
            let c = ca[i];
            while (c.charAt(0) === " ")
            {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEq) === 0)
            {
                return c.substring(nameEq.length, c.length);
            }
        }
        return null;
    }

    private static createCookie(name: string, value: string, days: number)
    {
        let expires: string;

        if (days)
        {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toUTCString()}`;
        }
        else
        {
            expires = "";
        }

        document.cookie = name + "=" + value + expires + "; path=/";
    }

    private static getPreferredStyleSheet(): string
    {
        let i: number;
        let a: HTMLLinkElement;
        for (i = 0; (a = document.getElementsByTagName("link")[i]); i++)
        {
            if (a.getAttribute("rel").indexOf("style") !== -1
                && a.getAttribute("rel").indexOf("alt") === -1
                && a.getAttribute("title"))
            {
                return a.getAttribute("title");
            }
        }
        return null;
    }

    private static getActiveStyleSheet(): string
    {
        let i: number;
        let a: HTMLLinkElement;
        for (i = 0; (a = document.getElementsByTagName("link")[i]); i++)
        {
            if (a.getAttribute("rel").indexOf("style") !== -1 && a.getAttribute("title") && !a.disabled)
            {
                return a.getAttribute("title");
            }
        }
        return null;
    }

    static setActiveStyleSheet(title): void
    {
        let i: number;
        let a: HTMLLinkElement;
        for (i = 0; (a = document.getElementsByTagName("link")[i]); i++)
        {
            if (a.getAttribute("rel").indexOf("style") !== -1 && a.getAttribute("title"))
            {
                a.disabled = true;
                if (a.getAttribute("title") === title)
                {
                    a.disabled = false;
                }
            }
        }
    }

    static stylesheetOnLoad(): void
    {
        const cookie = StyleSwitcher.readCookie("style");
        const title = cookie
                          ? cookie
                          : StyleSwitcher.getPreferredStyleSheet();
        StyleSwitcher.setActiveStyleSheet(title);
    }

    static stylesheetOnUnload(ev: JQueryEventObject): void
    {
        const title = StyleSwitcher.getActiveStyleSheet();
        StyleSwitcher.createCookie("style", title, 365);
    }
}